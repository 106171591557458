import { operatorActiveEdit, myOperatorInfo, userList } from "@/api/api";
import { projectName } from "@/utils/config";
export default {
  name: "index",
  data() {
    return {
      cityValue: [],
      goodsCodeValue: [],
      projectName: projectName,
      dialogVisible: false,
      dialogImageUrl: '',
      token: localStorage.getItem('token'),
      docKeys: ['year'],
      insureValue: [],
      Info: {
        id: '',
        name: '',
        start_date: '',
        end_date: '',
        server_guiding_price: '',
        server_settle_price: '',
        operator_file_path_arr: []
      },
      activeInfo: [],
      teachertorList: [],
      fieldtorList: [],
      type: 0,
      is_info: '',
      rules: {
        serve_settler_price: [{
          required: true,
          message: "请填写结算价",
          trigger: 'blur'
        }]
      }
    };
  },
  created() {},
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.id) {
      this.Info.id = data.id;
      this.getInfo();
    }
    this.getUserList();
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    getInfo() {
      myOperatorInfo({
        id: this.Info.id
      }).then(res => {
        this.Info = res.data;
        if (this.Info.operator_file_path_arr) {
          this.Info.operator_file_path_arr = JSON.parse(this.Info.operator_file_path_arr);
        } else {
          this.Info.operator_file_path_arr = [];
        }
        if (this.Info.time_frame) {
          this.Info.time_frame = JSON.parse(this.Info.time_frame);
        } else {
          this.Info.time_frame = [];
        }
        if (this.Info.active) {
          this.activeInfo = res.data.active;
          if (this.activeInfo.file_path_arr) {
            this.activeInfo.file_path_arr = JSON.parse(this.activeInfo.file_path_arr);
          } else {
            this.activeInfo.file_path_arr = [];
          }
        }
        console.log(this.Info);
      });
    },
    getUserList() {
      userList({
        table_name: 'e2_teachertor'
      }).then(res => {
        this.teachertorList = res.data;
      });
      userList({
        table_name: 'e2_fieldtor'
      }).then(res => {
        this.fieldtorList = res.data;
      });
    },
    subData() {
      //提交
      if (!this.Info.time_frame) {
        this.$root.ElMessage.error('请至少选择一个可选日期');
      }
      this.$refs['Info'].validate(valid => {
        if (valid) {
          operatorActiveEdit(this.Info).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                type: 'success',
                message: res.msg
              });
              this.$root.useRouter.replace({
                path: '/operators/active/activeList'
              });
            } else {
              this.$root.ElMessage.error(res.msg);
            }
          });
        }
      });
    }
  }
};